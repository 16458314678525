import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import FullWidthAlgoliaProductCarousel from "../../../../carousel/FullWidthAlgoliaProductCarousel"

const Container = styled.div<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "none")};
`

const Body = styled.div``

interface Props {
  collectionName: string
  backgroundColor?: string
  noLoop?: boolean
  showImpact?: boolean
  showImpactArrow?: boolean
  hidePrice?: boolean
  hideBrand?: boolean
  noLink?: boolean
  large?: boolean
  exVat?: boolean
  ignoreProductVisibility?: boolean
  useImpactImage?: boolean
  imageBackground?: string
}

const LPProductCarouselFullWidthBlock: FC<Props> = ({
  collectionName,
  backgroundColor,
  showImpact,
  showImpactArrow,
  hidePrice,
  hideBrand,
  noLink,
  large,
  exVat,
  ignoreProductVisibility,
  useImpactImage,
  imageBackground,
}) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Body>
        <FullWidthAlgoliaProductCarousel
          collectionName={collectionName}
          noLoop
          showImpact={showImpact}
          showImpactArrow={showImpactArrow}
          hidePrice={hidePrice}
          hideBrand={hideBrand}
          noLink={noLink}
          large={large}
          exVat={exVat}
          ignoreProductVisibility={ignoreProductVisibility}
          useImpactImage={useImpactImage}
          imageBackground={imageBackground}
        />
      </Body>
    </Container>
  )
}

export default LPProductCarouselFullWidthBlock
