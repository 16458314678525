import * as React from "react"
import styled from "styled-components"
import * as tracker from "../../tracking/tracker"
import { getAllImagesSmall } from "../../util/productUtil"
import GLink from "../../../gatsby/GLink"
import SquareImage from "../image/SquareImage"
import ArrowSwish from "../../../svg/arrow-swish.inline.svg"
import { ProductType } from "@social-supermarket/social-supermarket-model"
import { useContext } from "react"
import { BasketContext } from "../../context/BasketProvider"

const Container = styled.div`
  max-width: 500px;
  line-height: 20px;
  padding-bottom: 10px;
  position: relative;
  padding-bottom: 70px;
`
const Impact = styled.div`
  position: absolute;
  bottom: 0;
  left: 70px;
  font-size: 0.8em;
  width: calc(100% - 70px);
  height: 60px;
  display: flex;
  align-items: center;
`
const ArrowIcon = styled(ArrowSwish)`
  width: 60px;
  position: absolute;
  bottom: 43px;
  left: 10px;
  transform: scale(-1, -1) rotate(45deg);
`

interface Props {
  product: ProductType
  position: number
  showImpact?: boolean
  hidePrice?: boolean
  noLink?: boolean
  hideBrand?: boolean
  collectionName?: string
}

const ImpactProductCardWithArrow = ({
  product,
  position,
  showImpact,
  hidePrice,
  noLink,
  hideBrand,
  collectionName,
}: Props) => {
  const { queryId } = useContext(BasketContext)

  const trackClick = () => {
    tracker.productClick(product, position, queryId, collectionName)
  }

  const images = getAllImagesSmall(product)

  const title = product.shortTitle ? product.shortTitle : product.name

  return (
    <Container>
      {!noLink ? (
        <GLink to={`/product/${product.slug}`} onClick={trackClick}>
          <SquareImage
            image={images[0]}
            secondImage={images[1]}
            sizes={"300px"}
            backgroundColor={product.imageBackground}
          />
        </GLink>
      ) : (
        <SquareImage
          image={images[0]}
          secondImage={images[1]}
          sizes={"300px"}
          backgroundColor={product.imageBackground}
        />
      )}
      <ArrowIcon />
      <Impact>{product.productImpact}</Impact>
    </Container>
  )
}

export default ImpactProductCardWithArrow
