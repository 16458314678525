import * as React from "react"
import { SwiperSlide } from "swiper/react"
import ProductCard from "../product-list/ProductCard"
import styled from "styled-components"
import FullWidthCarousel from "./FullWidthCarousel"
import ImpactProductCardWithArrow from "../product-list/ImpactProductCardWithArrow"
import { desktop, largeDesktop, smallMobile, tablet } from "../../constants/screenSizes"
import { ProductType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div``

interface Props {
  products: ProductType[]
  noLoop?: boolean
  showImpact?: boolean
  showImpactArrow?: boolean
  hidePrice?: boolean
  hideBrand?: boolean
  noLink?: boolean
  large?: boolean
  exVat?: boolean
  useImpactImage?: boolean
  imageBackground?: string
}

const FullWidthProductCarousel = ({
  products,
  showImpact,
  showImpactArrow,
  hidePrice,
  hideBrand,
  noLink,
  large,
  exVat,
  useImpactImage,
  noLoop,
  imageBackground = "#f7f9f7",
}: Props) => {
  return (
    <Container>
      <FullWidthCarousel
        loop={!noLoop}
        swiperProps={{
          breakpoints: large ? LARGE_BREAKPOINTS : BREAKPOINTS,
        }}
      >
        {products.map((product, i) => (
          <SwiperSlide key={`${i}-${product.productId}`}>
            {!showImpactArrow ? (
              <ProductCard
                key={`${i}-${product.productId}`}
                product={{ ...product, imageBackground }}
                position={i}
                showImpact={showImpact}
                hidePrice={hidePrice}
                noLink={noLink}
                hideBrand={hideBrand}
                exVat={exVat}
                useImpactImage={useImpactImage}
              />
            ) : (
              <ImpactProductCardWithArrow
                key={`${i}-${product.productId}`}
                product={{ ...product, imageBackground }}
                position={i}
                showImpact={showImpact}
                hidePrice={hidePrice}
                noLink={noLink}
                hideBrand={hideBrand}
              />
            )}
          </SwiperSlide>
        ))}
        {/*<SwiperSlide>*/}
        {/*  <FinalSlide />*/}
        {/*</SwiperSlide>*/}
      </FullWidthCarousel>
    </Container>
  )
}

const BREAKPOINTS = {
  0: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    pagination: false,
  },
  [smallMobile]: {
    slidesPerView: 3,
    slidesPerGroup: 1,
    pagination: false,
  },
  [tablet]: {
    slidesPerView: 3,
    slidesPerGroup: 1,
    pagination: false,
  },
  [desktop]: {
    slidesPerView: 4,
    slidesPerGroup: 1,
    pagination: false,
  },
  [largeDesktop]: {
    slidesPerView: 5,
    slidesPerGroup: 1,
    pagination: false,
  },
}

const LARGE_BREAKPOINTS = {
  0: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    pagination: false,
  },
  [smallMobile]: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    pagination: false,
  },
  [tablet]: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    pagination: false,
  },
  [desktop]: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    pagination: false,
  },
  [largeDesktop]: {
    slidesPerView: 4,
    slidesPerGroup: 1,
    pagination: false,
  },
}

export default FullWidthProductCarousel
