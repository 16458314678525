import * as React from "react"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
// @ts-ignore
import { Swiper } from "swiper/react"
import SwiperClass from "swiper/types/swiper-class"
import { screenSizes } from "@social-supermarket/social-supermarket-components"
import { tablet } from "../../constants/screenSizes"

const Container = styled.div<{ height: number }>`
  min-height: ${({ height }) => (height ? `${height}px` : "initial")};
  position: relative;
  width: calc(100% + 100px);
  margin-left: -50px;
  padding: 20px 0;
`
const SwiperContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;

  .swiper-wrapper .swiper-slide:first-child {
    margin-left: 70px;
  }
`

const LeftZone = styled.div`
  height: 100%;
  width: 130px;
  position: absolute;
  left: 50px;
  top: 0;
  z-index: 10;
  opacity: 0.5;

  cursor: url("data:image/svg+xml;charset=utf-8,%3Csvg width='47' height='46' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-5 -5.5)' stroke='%23000' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-width='.75' style='mix-blend-mode:multiply' transform='rotate(165 28.5 28.5)' cx='28.5' cy='28.5' r='22.5'/%3E%3Cpath d='m27.5 22-6.39 7.144 6.39 7.144'/%3E%3Cpath stroke-linecap='square' d='M22 29.16h13.545'/%3E%3C/g%3E%3C/svg%3E")
      16 16,
    w-resize;

  @media (max-width: ${tablet}px) {
    display: none;
  }
`
const RightZone = styled.div`
  height: 100%;
  width: 130px;
  position: absolute;
  right: 50px;
  top: 0;
  z-index: 10;
  opacity: 0.5;
  cursor: url("data:image/svg+xml;charset=utf-8,%3Csvg width='47' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000'%3E%3Ccircle stroke-width='.75' style='mix-blend-mode:multiply' transform='rotate(-14 23.5 24)' cx='23.5' cy='24' r='22.5'/%3E%3Cpath d='m24.5 31.5 6.39-7.144-6.39-7.144'/%3E%3Cpath stroke-linecap='square' d='M30 24.34H16.455'/%3E%3C/g%3E%3C/svg%3E")
      16 16,
    e-resize;
  @media (max-width: ${tablet}px) {
    display: none;
  }
`

const RightCursor = styled.div``

const LeftCursor = styled.div``

const BREAK_POINTS = {
  [screenSizes.smallMobile]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  [screenSizes.tablet]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    pagination: false,
  },
  [screenSizes.desktop]: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    pagination: false,
  },
}

interface Props {
  loop?: boolean
  children: React.ReactNode
  swiperProps?: object
}

const FullWidthCarousel = ({ loop = true, children, swiperProps = {} }: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass>(null)
  const [show, setShow] = useState<boolean>(true)
  const containerRef = useRef(null)
  const leftRef = useRef(null)
  const rightRef = useRef(null)
  const [height, setHeight] = useState<number>()

  // @ts-ignore
  const hideArrows = swiper && swiper.snapGrid?.length <= 1

  useEffect(() => {
    setHeight(containerRef.current.offsetHeight)
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 50)
  }, [])

  return (
    <Container ref={containerRef} height={height}>
      <RightCursor />
      <LeftCursor />
      <LeftZone onClick={() => swiper && swiper.slidePrev()} />
      <RightZone onClick={() => swiper && swiper.slideNext()} />
      {show && (
        <SwiperContainer>
          <Swiper
            onSwiper={swiper => setSwiper(swiper)}
            slidesPerView={1}
            slidesPerGroup={1}
            pagination={{ clickable: true }}
            spaceBetween={20}
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
            }}
            breakpoints={BREAK_POINTS}
            loop={loop}
            {...swiperProps}
          >
            {children}
          </Swiper>
        </SwiperContainer>
      )}
    </Container>
  )
}

export default FullWidthCarousel
